import React, { Component, Fragment } from 'react';
import { navigate } from 'gatsby';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import classnames from 'classnames';
import styled from 'styled-components';
import ScrollBar from 'react-overlayscrollbars';
import { cubicBezier } from '../../assets/styles/constants';
import MenuItem from './MenuItem';
import css from './SectionMenu.module.css';

class SectionMenu extends Component {
  state = {
    openedMenu: false,
    currentOpenUri: this.props.currentUri,
  }

  handleOnClick = () => {
    this.setState({ openedMenu: !this.state.openedMenu })
  }

  handleSectionItemClick = ({ uri, index: sectionIndex }) => {
    const { currentUri, onScrollToSection } = this.props

    if (currentUri === uri) {
      // Scroll section on the current product page
      // onSectionClick(clickedSectionId)
      onScrollToSection(sectionIndex)

      // Close menu
      this.setState({ openedMenu: false });
    } else {
      // Navigate to the clicked product page, and then scroll to section
      navigate(`${uri}#${sectionIndex + 1}`);
    }
  }

  handleMenuItemClick = (uri) => {
    const { currentOpenUri } = this.state;
    this.setState({ currentOpenUri: uri === currentOpenUri ? null : uri })
  }

  render() {
    const { currentSectionIndex, currentUri, parent } = this.props;
    const { openedMenu, currentOpenUri } = this.state;

    // Siblings of current page
    const siblings = get(parent, 'wpChildren.nodes', []) || [];

    // Don't show the menu if there's no parent or siblings to show
    if (!parent || !siblings.length) {
      return null;
    }

    return (
      <Fragment>
        <SectionMenuButton onClick={this.handleOnClick} className={!openedMenu && 'presentation-mode-control'}>
          <SectionMenuButtonLabel openedMenu={openedMenu}>
            <span className="opened">Hide</span>
            <span className="closed">Overview</span>
          </SectionMenuButtonLabel>
          <SectionMenuButtonTrigger className="section-menu-button-trigger" openedMenu={openedMenu}>
            <svg width="15px" height="14px" viewBox="0 0 15 14">
              <g fill="#000">
                <path d="M1.61326531,3.2372449 C2.50408163,3.2372449 3.22653061,2.51173469 3.22653061,1.62397959 C3.22653061,0.73622449 2.50408163,0.00765306122 1.61326531,0.00765306122 C0.72244898,0.00765306122 0,0.733163265 0,1.62091837 C0,2.50867347 0.725510204,3.2372449 1.61326531,3.2372449 Z M1.61326531,0.757653061 C2.09081633,0.757653061 2.47653061,1.14642857 2.47653061,1.62091837 C2.47653061,2.09540816 2.0877551,2.48418367 1.61326531,2.48418367 C1.13877551,2.48418367 0.75,2.09540816 0.75,1.62091837 C0.75,1.14642857 1.13877551,0.757653061 1.61326531,0.757653061 Z" />
                <path d="M5.38469388,1.99744898 L14.6142857,1.99744898 C14.822449,1.99744898 14.9908163,1.82908163 14.9908163,1.62091837 C14.9908163,1.4127551 14.822449,1.24438776 14.6142857,1.24438776 L5.38469388,1.24438776 C5.17653061,1.24438776 5.00816327,1.4127551 5.00816327,1.62091837 C5.00816327,1.82908163 5.17653061,1.99744898 5.38469388,1.99744898 Z" />
              </g>
              <g transform="translate(0.000000, 4.000000)" fill="#000">
                <path d="M1.61326531,4.22091837 C2.50408163,4.22091837 3.22653061,3.49540816 3.22653061,2.60765306 C3.22653061,1.71989796 2.50408163,0.994387755 1.61326531,0.994387755 C0.72244898,0.994387755 0,1.71683673 0,2.60765306 C0,3.49846939 0.725510204,4.22091837 1.61326531,4.22091837 Z M1.61326531,1.74438776 C2.09081633,1.74438776 2.47653061,2.13316327 2.47653061,2.60765306 C2.47653061,3.08214286 2.0877551,3.47091837 1.61326531,3.47091837 C1.13877551,3.47091837 0.75,3.08520408 0.75,2.60765306 C0.75,2.13010204 1.13877551,1.74438776 1.61326531,1.74438776 Z" />
                <path d="M5.38469388,2.98418367 L14.6142857,2.98418367 C14.822449,2.98418367 14.9908163,2.81581633 14.9908163,2.60765306 C14.9908163,2.3994898 14.822449,2.23112245 14.6142857,2.23112245 L5.38469388,2.23112245 C5.17653061,2.23112245 5.00816327,2.3994898 5.00816327,2.60765306 C5.00816327,2.81581633 5.17653061,2.98418367 5.38469388,2.98418367 Z" />
              </g>
              <g transform="translate(0.000000, 9.000000)" fill="#000">
                <path d="M1.61326531,4.20765306 C2.50408163,4.20765306 3.22653061,3.48214286 3.22653061,2.59438776 C3.22653061,1.70357143 2.50102041,0.981122449 1.61326531,0.981122449 C0.725510204,0.981122449 0,1.70663265 0,2.59438776 C0,3.48214286 0.725510204,4.20765306 1.61326531,4.20765306 Z M1.61326531,1.72806122 C2.09081633,1.72806122 2.47653061,2.11683673 2.47653061,2.59132653 C2.47653061,3.06887755 2.0877551,3.45459184 1.61326531,3.45459184 C1.13877551,3.45459184 0.75,3.06581633 0.75,2.59132653 C0.75,2.11683673 1.13877551,1.72806122 1.61326531,1.72806122 Z" />
                <path d="M5.38469388,2.96785714 L14.6142857,2.96785714 C14.822449,2.96785714 14.9908163,2.7994898 14.9908163,2.59132653 C14.9908163,2.38316327 14.822449,2.21479592 14.6142857,2.21479592 L5.38469388,2.21479592 C5.17653061,2.21479592 5.00816327,2.38316327 5.00816327,2.59132653 C5.00816327,2.7994898 5.17653061,2.96785714 5.38469388,2.96785714 Z" />
              </g>
            </svg>
          </SectionMenuButtonTrigger>
        </SectionMenuButton>
        <SectionMenuWrapper openedMenu={openedMenu}>
          <ScrollBar 
            className={classnames('os-theme-light', css.scrollContainer)}
            id="section-menu-scrollbar"
            overflow-behavior={{ x: 'hidden' }}
            scrollbars={{ autoHide: 'l' }}
          >
            <SectionMenuWrapperInner className="overlay">
              <MenuItem data={parent} isParent hasArrow={false} />
              {orderBy(siblings, p => p.menuOrder)
              .filter(sibling => get(sibling, 'options.showInOverviewMenu'))
              .map((sibling, index) => {
                return (
                  <MenuItem
                    currentUri={currentUri}
                    currentOpenUri={currentOpenUri}
                    key={`product-${sibling.uri}`}
                    number={index + 1}
                    data={sibling}
                    onClick={this.handleSectionItemClick}
                    handleMenuItemClick={this.handleMenuItemClick}
                    currentSectionIndex={currentSectionIndex}
                  />
                )
              })}
            </SectionMenuWrapperInner>
          </ScrollBar>
        </SectionMenuWrapper>
        <SectionMenuOverlay 
          visible={openedMenu}
          onClick={this.handleOnClick}
        />
      </Fragment>
    )
  }
}

export default SectionMenu

const SectionMenuOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9994;
  transition: opacity 0.5s;

  ${({ visible }) => visible ? `
    opacity: 1;
    pointer-events: all;
  ` : `
    opacity: 0;
    pointer-events: none;
  `}
`;

const SectionMenuButton = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 9996;
  top: 50px;
  right: 50px;
  user-select: none;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }

  &:focus {
    outline: none;
  }
`

const SectionMenuButtonTrigger = styled.div`
  width: 37px;
  height: 37px;
  border-radius: 100%;
  border: 1px solid #000;
  box-shadow: 0 0 1px 0px #000 inset, 0 0 1px 0px #000;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before,
  &:after {
    content: '';
    background: #000;
    height: 1px;
    width: 15px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -8px;
    transform: rotate(45deg);
    transition: all 0.5s ${cubicBezier};
    opacity: ${props => (props.openedMenu ? 1 : 0)};
  }

  > svg {
    transition: all 0.5s ${cubicBezier};
    opacity: ${props => (props.openedMenu ? 0 : 1)};
  }

  &:after {
    transform: rotate(135deg);
  }
`

const SectionMenuButtonLabel = styled.span`
  color: #000;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 30px;
  position: relative;
  height: 10px;
  text-align: right;

  > span {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 150px;
    transition: all 0.5s ${cubicBezier};

    &.opened {
      opacity: ${props => (props.openedMenu ? 1 : 0)};
      bottom: ${props => (props.openedMenu ? '-6px' : '-20px')};
    }
    &.closed {
      opacity: ${props => (props.openedMenu ? 0 : 1)};
      top: ${props => (props.openedMenu ? '-20px' : '-2px')};
    }
  }
`

const SectionMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9995;
  background: #FFF;
  width: 100%;
  max-width: 42rem;
  height: 100vh;
  transition: all 0.5s ${cubicBezier};
  transform: ${props => (props.openedMenu ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0, 0)')};
`

const SectionMenuWrapperInner = styled.div`
  -webkit-overflow-scrolling: touch;
  height: 100%;
  padding: 48px;
`