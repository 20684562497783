import React from 'react'
import styled from 'styled-components'

import { cubicBezier } from '../assets/styles/constants'

export default props => {
  return (
    <ScrollIndicator showScrollIndicator={props.showScrollIndicator} className="presentation-mode-control">
      <svg width="18px" height="26px" viewBox="0 0 18 26">
        <rect className="mouse-wheel" fill="#000" x="8" y="4" width="2" height="5" rx="1" />
        <path
          d="M9,1 C4.581722,1 1,4.581722 1,9 L1,17 C1,21.418278 4.581722,25 9,25 C13.418278,25 17,21.418278 17,17 L17,9 C17,4.581722 13.418278,1 9,1 Z M9,0 C13.9705627,-9.13077564e-16 18,4.02943725 18,9 L18,17 C18,21.9705627 13.9705627,26 9,26 C4.02943725,26 0,21.9705627 0,17 L0,9 C0,4.02943725 4.02943725,9.13077564e-16 9,0 Z"
          fill="#000"
        />
      </svg>
      <span>{props.label}</span>
    </ScrollIndicator>
  )
}

const ScrollIndicator = styled.div`
  position: fixed;
  bottom: 30px;
  z-index: 900;
  opacity: ${props => (props.showScrollIndicator ? '0.5' : '0')};
  transition: all 1s ${cubicBezier} 1s;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-self: center;
  pointer-events: none;

  > span {
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 13px;
    margin-top: 10px;
  }

  @keyframes movingMouseWheel {
    0% {
      transform: translateY(0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translateY(10px);
      opacity: 0;
    }
  }

  .mouse-wheel {
    animation: movingMouseWheel 2s ${cubicBezier} infinite;
  }
`
