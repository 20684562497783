/**
 * Video player
 */

import React, { Component, createRef } from 'react';
import classnames from 'classnames';
import { Player, ControlBar, ClosedCaptionButton, ReplayControl } from 'video-react';
import get from 'lodash/get';
import { getVideoFilePath, isOffline } from '@utils';
import { HLSSource } from './components';
import css from './Video.module.css';

const BackButton = ({ onClick, children }) => (
  <button className={classnames(css.button, css.isBack)} onClick={onClick}>
    <svg className={css.button__icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" data-test-icon-element="true" focusable="false"><path d="M28.2 14.5h-20l6.2-6.2c.8-.8.8-2 0-2.8s-2-.8-2.8 0l-9.4 9.6c-.8.8-.8 2 0 2.8l9.6 9.6c.8.8 2 .8 2.8 0s.8-2 0-2.8l-6.2-6.2h20c1.2 0 2-.8 2-2s-1-2-2.2-2z"></path></svg>
    <span className={css.button__label}>
    {children}
    </span>
  </button>
);

const CloseButton = ({ onClick }) => (
  <button className={classnames(css.button, css.isClose)} onClick={onClick} aria-label="Close">
    <svg className={css.button__icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M4.6 27.4c.8.8 2 .8 2.8 0l8.6-8.6 8.6 8.6c.8.8 2 .8 2.8 0s.8-2 0-2.8L18.8 16l8.6-8.6c.8-.8.8-2 0-2.8s-2-.8-2.8 0L16 13.2 7.4 4.6c-.8-.8-2-.8-2.8 0s-.8 2 0 2.8l8.6 8.6-8.6 8.6c-.8.8-.8 2 0 2.8z"/></svg>
  </button>
);

class Video extends Component {
  static defaultProps = {
    aspectRatio: '16:9',
    autoPlay: true,
  }

  constructor(props) {
    super(props);
    this.playerRef = createRef(null);

    this.state = {
      loadSource: false,
      hasEnded: false,
      visible: false,
      videoState: null,
    }
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    this.removeEventListeners();
    document.body.classList.remove('hide-controls');
  }

  componentDidUpdate({ path: prevPath }, { visible: prevVisible }) {
    const { path } = this.props;
    const { visible } = this.state;

    if (!prevVisible && visible) {
      document.body.classList.add('hide-controls');
    } else if (prevVisible && !visible) {
      document.body.classList.remove('hide-controls');
    }

    if (path && path !== prevPath) {
      this.removeEventListeners();

      this.setState({
        loadSource: false
      }, () => {
        this.init();
      });
    }
  }

  init = () => {
    const { initialVolume } = this.props;
    this.player = this.playerRef.current;
    this.player.subscribeToStateChange(this.handleStateChange);
    this.setupEventListeners();
    document.body.classList.add('hide-controls');

    this.setState({
      loadSource: true,
    }, () => {
      // Set initial volumne
      if (initialVolume) {
        this.player.volume = initialVolume;
      }

      setTimeout(() => {
        this.setState({
          visible: true,
        })
      }, 100);
    })
  }

  removeEventListeners = () => {
    document.removeEventListener('keydown', this.handleKeyDown, true);
  }

  setupEventListeners = () => {
    document.addEventListener('keydown', this.handleKeyDown, true);
  }

  handleKeyDown = e => {
    const { key } = e;
    const { onEscape } = this.props;

    switch (key) {
      case 'Escape':
        if (typeof onEscape === 'function') {
          onEscape(e, this.player);
        }

        this.handleClose(e);
        break;
      default:
    }
  }

  handleClose = e => {
    const { onClose } = this.props;

    if (typeof onClose === 'function') {
      onClose(this.getCallbackData(), e)
    }
  }

  /**
   * HACK 👻
   * Microsoft Edge fix until they fix it in react-video
   */
  MSEdgeFix = () => {
    if (document.querySelector(".video-react")) {
      const element = document.querySelector(".video-react");
      if (element && element.classList.contains("video-react-playing") && element.classList.contains("video-react-waiting")) {
        element.classList.remove("video-react-waiting");
      }
    }
  }

  handleStateChange = videoState => {
    const { onVideoStateChange, onEnded } = this.props;

    if (typeof onVideoStateChange === 'function') {
      onVideoStateChange(videoState);
    }

    this.MSEdgeFix();

    // Video has ended
    if (videoState.ended && !this.state.hasEnded) {
      this.setState({
        hasEnded: true,
      }, () => {
        if (typeof onEnded === 'function') {
          onEnded(this.getCallbackData());
        }
      });
    }
  }

  getVideoFile = () => {
    const { path } = this.props;
    const fileName = isOffline ? 'original.mp4' : 'index.m3u8';
    return getVideoFilePath(path, fileName);
  }

  getCallbackData = () => {
    const playerState = this.player.getState();

    // Data passed to the video component 
    // that will be sent back with any relevent callbacks
    const { meta } = this.props;
    
    return {
      ...playerState,
      meta
    }
  }

  renderSource = () => {
    const { loadSource } = this.state;

    // Wait until the video is mounted to load the sauce
    if (!loadSource) {
      return null;
    }

    const src = this.getVideoFile();

    // Load mp4 in offline app
    if (isOffline) {
      return (
        <source src={src} />
      )
    }

    // Load HLS on web
    return (
      <HLSSource
        path={this.props.path}
        src={src}
      />
    )
  }

  /**
  * Captions
  */
  hasCaptions = () => {
    return !!this.props.captions;
  }

  renderCaptions = () => {
    const { captions } = this.props;

    if (!this.hasCaptions()) {
      return null;
    }

    return captions
      .sort((a, b) => a.locale.localeCompare(b.locale))
      .map((caption, index) => {
        const src = get(caption, 'file.url.localFile.publicURL');

        return (
          <track
            key={index}
            kind="captions"
            src={src}
            srcLang={caption.locale}
            label={caption.label}
          />
        )
      })
  }

  render() {
    const { autoPlay, backButtonLabel, aspectRatio, startTime, className, ...rest } = this.props;
    const { visible } = this.state;

    return (
      <div className={classnames(css.root, { [css.visible]: visible }, className)}>
        <Player
          ref={this.playerRef}
          className={css.player}
          autoPlay={autoPlay}
          aspectRatio={aspectRatio}
          startTime={startTime}
          playsInline
          {...rest}
        >
          {this.renderSource()}
          {this.renderCaptions()}
          {backButtonLabel && (
            <BackButton onClick={this.handleClose}>
              {backButtonLabel}
            </BackButton>
          )}
          <CloseButton onClick={this.handleClose} />
          <ControlBar>
            <ReplayControl seconds={10} order={2.3} />
            {this.hasCaptions() && <ClosedCaptionButton order={7} />}
          </ControlBar>
        </Player>
      </div>
    )
  }
}

export default Video;
