import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import posed from 'react-pose';
import { cubicBezier, MQ } from '../../assets/styles/constants';

const AccordionContent = posed.div({
  closed: { height: 0 },
  open: { height: 'auto' }
});

class MenuItem extends React.Component {
  render() {
    const {
      data,
      currentUri,
      currentOpenUri,
      onClick,
      handleMenuItemClick,
      currentSectionIndex,
      isParent,
    } = this.props


    const openedMenuItemMenu = currentOpenUri === data.uri;
    const sections = get(data, 'pagebuilder.sections') || [];

    return (
      <MenuItemWrapper key={`menu-item-${data.uri}`}>
        <ProductTitle
          isActive={currentOpenUri === data.uri}
          isParent={isParent}
          onClick={() => {
            // Open and show sections if there are more than one
            if (sections.length > 1) {
              handleMenuItemClick(data.uri)
            // Otherwise just navigate to the page
            } else {
              navigate(data.uri)
            }
          }}
          openedMenuItemMenu={openedMenuItemMenu}
          className="product-item-title"
          dangerouslySetInnerHTML={{ __html: get(data, 'options.overviewMenuTitle') || data.title }}
          hasArrow={sections.length > 1}
        />
        { sections.length > 1 ? (
          <ProductSections openedMenuItemMenu={openedMenuItemMenu} pose={openedMenuItemMenu ? 'open' : 'closed'}>
            {sections.map((section, index) => {
              return (
                <Section
                  className="product-item-section"
                  key={`menu-sub-item-${section.sectionTitle}-${index}`}
                  index={index}
                  currentOpenUri={currentOpenUri}
                  currentUri={currentUri}
                  currentSectionIndex={currentSectionIndex}
                  onClick={() => {
                    onClick({
                      uri: data.uri,
                      index,
                    })
                   }
                  }
                >
                  <span dangerouslySetInnerHTML={{ __html: section.sectionTitle }} />
                </Section>
              )
            })}
        </ProductSections>
        ): null}
      </MenuItemWrapper>
    )
  }
}

export default MenuItem;

const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
`

const ProductTitle = styled.button`
  display: flex;
  height: 44px;
  align-items: center;
  font-size: ${({ isParent }) => isParent ? '1.5rem' : '1.2rem'};
  ${({ isParent }) => isParent ? 'font-weight: bold;' : ''};
  ${({ isParent }) => isParent ? 'margin-bottom: 20px;' : ''};
  ${({ isActive }) => isActive ? 'font-weight: bold;' : ''};
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  padding-right: 1rem;
  position: relative;
  cursor: pointer;
  user-select: none;

  &:before,
  &:after {
    content: '';
    background: ${props => (props.openedMenuItemMenu ? '#000' : '#5e5e5e')};
    width: 10px;
    height: 3px;
    position: absolute;
    top: 50%;
    transition: all 0.5s ${cubicBezier};
    ${({ hasArrow }) => hasArrow ? 'display: block;' : 'display:none;'}
  }

  &:hover {
    font-weight: 700;
  }

  &:before {
    right: 5px;
    transform: ${props => (props.openedMenuItemMenu ? 'rotate(-45deg)' : 'rotate(-135deg)')};
  }
  &:after {
    right: 0;
    transform: ${props => (props.openedMenuItemMenu ? 'rotate(45deg)' : 'rotate(135deg)')};
  }
`

const ProductSections = styled(AccordionContent)`
  overflow: hidden;
  // transition: all 0.5s ${cubicBezier};
  //  max-height: ${props => (props.openedMenuItemMenu ? '1500px' : '0px')};
`

const Section = styled.button`
  display: flex;
  width: 100%;
  padding: 20px 0;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 14px;
  position: relative;
  transition: all 0.5s ${cubicBezier};
  cursor: pointer;
  user-select: none;
  opacity: ${props =>
    props.currentOpenUri === props.currentUri && props.currentSectionIndex === props.index
      ? '1'
      : '0.5'};

  @media screen and (${MQ.lg}) {
    &:hover {
      opacity: 1;
      font-weight: 700;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate3d(0, -50%, 0);
    width: 14px;
    height: 14px;
    border-radius: 100%;
    transition: all 0.5s ${cubicBezier};
    background: ${props =>
      props.currentOpenUri === props.currentUri && props.currentSectionIndex === props.index
        ? 'rgba(0, 0, 0, 1)'
        : 'rgba(0, 0, 0, 0)'};
    border: 1px solid #000;
  }
`
