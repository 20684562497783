/**
 * Components -> Flickity Arrow
 */

import React from 'react';
import classnames from 'classnames';
import css from './FlickityArrow.module.css';

const FlickityArrow = ({ 
  animate,
  className,
  direction,
  disabled,
  hide,
  onClick,
}) => {
  return (
    <button
      disabled={hide || disabled}
      className={classnames(
        css.root,
        {[css[direction]]: direction },
        {[css.disabled]: disabled || hide },
        {[css.hide]: hide },
        {[css.animate]: animate },
        className,
      )}
      onClick={onClick}
      >
      <svg viewBox="0 0 17 13">
        <path
          d="M11.4476024,0.854471502 C11.2160051,0.613842833 10.8305494,0.613842833 10.5908542,0.854471502 C10.3592569,1.08697082 10.3592569,1.47392774 10.5908542,1.70588511 L14.9350587,6.06700876 L0.599777707,6.06700876 C0.26560813,6.06755072 0,6.3341933 0,6.66966435 C0,7.0051354 0.26560813,7.28044928 0.599777707,7.28044928 L14.9350587,7.28044928 L10.5908542,11.6334436 C10.3592569,11.8740723 10.3592569,12.2615711 10.5908542,12.4935285 C10.8305494,12.7341572 11.2165449,12.7341572 11.4476024,12.4935285 L16.8202286,7.09997778 C17.0599238,6.86747846 17.0599238,6.48052154 16.8202286,6.24856418 L11.4476024,0.854471502 Z"
        />
      </svg>
    </button>
  )
}

export default FlickityArrow;
