/**
 * useWithIds
 * Loops over an array of objects and applies an ID attribute
 */

import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

const useWithIds = (arr) => {
  return useMemo(() => (arr || []).map((data) => ({ ...data, id: uuid() })), [arr]);
};

export default useWithIds;
