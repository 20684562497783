/**
 * MainMenu
 * 
 * Note: This component will be used to create navigation menus 
 * for both "Collection Highlights" and "Full Collection"
 */

/* eslint-disable jsx-a11y/mouse-events-have-key-events */

import React, { Component } from 'react';
import get from 'lodash/get';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { isOffline } from '@utils';
import setTheme from '@utils/setTheme';
import BackgroundImage from 'gatsby-background-image';
import css from './MainMenu.module.css';

class MainMenu extends Component {
  state = {
    open: false,
    hoveredSlug: '',
    previousTheme: '',
  }

  handleOnMouseOver = slug => {
    const { hoveredSlug } = this.state;
    if (hoveredSlug !== slug) {
      this.setState({ hoveredSlug: slug })
    }
  }

  handleOnClick = () => {
    this.toggle();
  }

  toggle = () => {
    this.setState({ open: !this.state.open })
  }

  componentDidMount() {
    this.setState({ hoveredSlug: window.location.pathname })
  }

  componentDidUpdate(_, prevState) {
    if (!prevState.open && this.state.open) {
      setTheme('light', previousTheme => this.setState({ previousTheme }));
    } else if (prevState.open && !this.state.open) {
      setTheme(this.state.previousTheme);
    }
  }

  handleItemClick = item => e => {
    if (typeof item.onClick === 'function') {
      item.onClick(item);
    }

    this.toggle();
  }

  render() {
    const { hoveredSlug, open } = this.state
    const { items, shortcut, showOnHover } = this.props;

    if (!Array.isArray(items) || !items.length) {
      return null;
    }
    
    return (
      <>
        <button 
          className={classnames(css.MainMenuButton, {[css.showOnHover]: showOnHover}, { [css.open]: open }, {'presentation-mode-control': !open})}  
          onClick={this.handleOnClick}
          aria-expanded={open} 
          aria-label="Navigation Button"
        >
          <div className={css.MainMenuButtonTrigger}>
            <span className={css.MainMenuButtonTrigger__barTop} />
            <span className={css.MainMenuButtonTrigger__barMid} />
            <span className={css.MainMenuButtonTrigger__barBot} />
          </div>
          <span className={css.MainMenuButtonLabel}>
            <span className={css.MainMenuButtonLabel__opened}>Close</span>
            <span className={css.MainMenuButtonLabel__closed}>Menu</span>
          </span>
        </button>
        <div className={classnames(css.MainMenuWrapper, {[css.open]: open})}>
          <div className={css.MainMenuInner}>
            <nav className={css.MainMenuItems}>
              {
                items.map((item, index) => {
                  const MenuItem = item.path ? Link : 'button';

                  const linkProps = !!item.path ? {
                    activeClassName: css.active,
                  } : {};

                  return (
                    <MenuItem
                      className={css.MainMenuItem}
                      key={`main-menu-item-${index}`}
                      to={item.path}
                      onClick={this.handleItemClick(item)}
                      onMouseOver={() => this.handleOnMouseOver(item.path)}
                      onMouseOut={() => this.setState({ hoveredSlug: '' })}
                      dangerouslySetInnerHTML={{ __html: item.label }}
                      {...linkProps}
                    />
                  );
                })
              }
              {shortcut && (
                <button
                  className={classnames(css.MainMenuItem, css.shortcut)}
                  key={`mainmenuitem-switch-version`}
                  onClick={shortcut.onClick}
                  // to={shortcut.to}
                >
                  <svg className={css.shortcut__icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" data-test-icon-element="true" focusable="false"><path d="M28.2 14.5h-20l6.2-6.2c.8-.8.8-2 0-2.8s-2-.8-2.8 0l-9.4 9.6c-.8.8-.8 2 0 2.8l9.6 9.6c.8.8 2 .8 2.8 0s.8-2 0-2.8l-6.2-6.2h20c1.2 0 2-.8 2-2s-1-2-2.2-2z"></path></svg>
                  {shortcut.label}
                </button>
              )}
            </nav>
            <div className={css.CurrentMenuItemTeaser} aria-hidden>
              {items.map((item, index) => {
                const image = get(item, 'image.localFile.childImageSharp.fluid', null);

                if (!image) {
                  return null;
                }
                
                const isVisible = hoveredSlug === item.path;

                return (
                  <div 
                    key={`menuitemteaser-${index}`}
                    className={classnames(css.CurrentMenuItemTeaser__backgroundImageWrap, { [css.isVisible]: isVisible })}
                  >
                    <BackgroundImage
                      critical={isOffline}
                      fluid={image}
                      className={classnames(css.CurrentMenuItemTeaser__backgroundImage)}
                      />
                  </div>
                )
              })}
              {/* <Logo className={css.logo} /> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MainMenu;
