/**
 * DefaultMainMenu
 * 
 * Note: This is the menu for the "Full Collection"
 */

import React, { useContext } from 'react';
import get from 'lodash/get';
import { StaticQuery, graphql, navigate } from 'gatsby';
import { logout } from '../../services/auth';
import AppContext from '@contexts/AppContext';
import { MainMenu } from '@components';

const DefaultMainMenu = () => {
  const { hideControls } = useContext(AppContext);

  const handleSignOut = () => {
    logout(() => {
      navigate(`/login/`);
    });
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          allWpPage(
            filter: { options: { showInMainMenu: { eq: true }}}
            sort: { fields: menuOrder, order: ASC }
            ) {
            nodes {
              uri
              title
              options {
                mainMenuTitle
                mainMenuImage {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1400, srcSetBreakpoints: [700]) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const items = get(data, 'allWpPage.nodes') || [];

        if (!items.length || hideControls) {
          return null;
        }
  
        const menuItems = items.map(({ uri, title, options: { mainMenuImage, mainMenuTitle } }) => ({
          label: mainMenuTitle || title,
          path: uri,
          image: mainMenuImage,
        }));
        
        return (
          <MainMenu
            shortcut={{
              onClick: handleSignOut,
              label: 'Sign out'
            }}
            items={menuItems}
          />
        );
      }}
    />
  );
}

export default DefaultMainMenu;
