
/**
 * Look for a data-theme attribute on the section element
 * or simply a string of either "light" or "dark"
 * to determine whether or not the theme should change
 */

 import get from 'lodash/get';
 const DEFAULT_THEME = 'light';

const setTheme = (sectionOrTheme, callback) => {
  if (!sectionOrTheme) {
    return;
  }

  const theme = (typeof sectionOrTheme === 'string' ? sectionOrTheme : get(sectionOrTheme, 'dataset.theme', 'light')) || DEFAULT_THEME;
  const html = document.querySelector('html'); 
  
  // Remove the opposite theme class if needed
  if (html.classList.contains(`theme-${theme === 'light' ? 'dark' : 'light'}`)) {
    html.classList.remove(theme === 'light' ? 'theme-dark' : 'theme-light');
  }

  // Pass previous theme to callback
  if (typeof callback === 'function') {
    callback(sessionStorage.getItem('previous-theme'));
  }

  html.classList.add(`theme-${theme}`);
  sessionStorage.setItem('previous-theme', theme);
}

export default setTheme;
