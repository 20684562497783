module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fastclick/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ECCO COLLECTION PREMIERE AW21","short_name":"ECCO COLLECTION PREMIERE AW21","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"src/assets/images/icon.png","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"9dcf7e4c79f5b15e2fbcebbcef23ab1e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress-experimental/gatsby-browser.js'),
      options: {"plugins":[],"schema":{"perPage":100,"timeout":60000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"url":"https://cp-admin.ecco.com/aw21/en/graphql","verbose":true,"develop":{"nodeUpdateInterval":5000,"hardCacheMediaFiles":false,"hardCacheData":false},"html":{"useGatsbyImage":true,"imageQuality":80,"imageMaxWidth":null,"fallbackImageMaxWidth":100,"createStaticFiles":true},"debug":{"graphql":{"writeQueriesToDisk":true,"showQueryVarsOnError":true,"panicOnError":false,"showQueryOnError":false,"copyQueryOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false},"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"type":{"Comment":{"exclude":true},"Post":{"exclude":true}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/athleisure/","/aw-2021/*","/winterisation/","/casualisation-of-formal/"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
