import React from 'react'
import styled from 'styled-components'

import { cubicBezier } from '../assets/styles/constants'

class FormGroup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isFocused: false,
    }
  }

  handleOnChange = event => {
    this.props.formOnChange(event)
  }

  handleOnFocus = () => {
    this.setState({ isFocused: true })
  }

  handleOnBlur = () => {
    this.setState({ isFocused: false })
  }

  render() {
    const { label, type, name, value } = this.props
    const { isFocused } = this.state
    const hasValue = !!value;
    return (
      <StyledFormGroup isFocused={isFocused}>
        <FormLabel hasValue={hasValue} isFocused={isFocused} htmlFor={`login-form-${label}`}>
          {label}
        </FormLabel>
        <FormInput
          autocomplete={false}
          id={`login-form-${label}`}
          type={type}
          name={name}
          value={value}
          onChange={this.handleOnChange}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
        />
      </StyledFormGroup>
    )
  }
}

export default FormGroup

const StyledFormGroup = styled.div`
  width: 100%;
  max-width: 500px;
  margin-bottom: 30px;
  opacity: ${props => (props.isFocused ? 1 : 0.6)};
  transition: all 0.5s ${cubicBezier};
`

const FormLabel = styled.label`
  display: block;
  font-size: 12px;
  position: relative;
  bottom: ${props => (props.isFocused || props.hasValue ? 0 : '-37px')};
  transition: all 0.8s ${cubicBezier};
  user-select: none;
  letter-spacing: 2px;
  pointer-events: none;
`

const FormInput = styled.input`
  height: 55px;
  width: 100%;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #000;
  color: #000;
  border-radius: 0;

  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus, 
  &:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  &:focus {
    outline: none;
  }
`
