/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/assets/styles/global.scss';
import 'react-overlayscrollbars/dist/es/index.css';

// InteractionObserver polyfill (needed for some browsers for gatsby-image to work properly)
import 'intersection-observer';

// Remove Gray Highlight When Tapping Links in Mobile Safari, 
// and then to allow :active styles to work in your CSS on a page in Mobile Safari:
document.addEventListener("touchstart", () => {}, true);

/**
 * Google Analytics
 */

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== `production` || typeof gtag !== `function`) {
    return null
  }

  // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined
    window.gtag(`set`, `page`, pagePath)
    window.gtag(`send`, `pageview`)
  }

  // Minimum delay for reactHelmet's requestAnimationFrame
  const delay = 50;
  setTimeout(sendPageView, delay)

  return null
}